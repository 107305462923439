document.addEventListener("DOMContentLoaded", () => {
	// Hamburger
	const hamburgerMenu = document.querySelector(".hamburger");
	if (hamburgerMenu) {
		const siteHeader = document.getElementById("header");
		const navbarNav = document.getElementById("nav");
		hamburgerMenu.addEventListener("click", () => {
			hamburgerMenu.classList.toggle("is-active");
			siteHeader.classList.toggle("is-active");
			navbarNav.classList.toggle("is-active");
		});
	}
  	// accordion
	const accordionElement = document.querySelector(".accordion");
	if (accordionElement) {
		var accordionHeading = document.querySelectorAll(".accordion__item > .accordion__heading");
		accordionHeading.forEach(function (toggle) {
			toggle.addEventListener("click", function () {
				var ariaSelected = this.getAttribute("aria-selected") === "true" ? "false" : "true";
				var ariaExpanded = this.getAttribute("aria-expanded") === "true" ? "false" : "true";
				this.parentElement.classList.toggle("accordion__item--active");
				this.setAttribute("aria-selected", ariaSelected);
				this.setAttribute("aria-expanded", ariaExpanded);
				var content = this.nextElementSibling;
				if (content.style.maxHeight) {
					content.style.maxHeight = null;
				} else {
					content.style.maxHeight = content.scrollHeight + "px";
				}
				var accordionItem = this.querySelector(".accordion__item");
				if (accordionItem) {
					icon.classList.toggle("accordion__item--expanded");
				}
			});
		});
	}
	new Swiper('[data-swiper="default"]', {
		slidesPerView: 1,
		spaceBetween: 16,
		breakpoints: {
			1024: {
				slidesPerView: 3,
				spaceBetween: 32,
			},
			768: {
				slidesPerView: 2,
				spaceBetween: 32,
			}
		}
	});
});